import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";

import { pageAfterLogin } from "@/custom_config.js";

console.log(pageAfterLogin);

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        beforeEnter: (to, from, next) => {
            const user = localStorage.getItem("userInfo");
            const ricordami = localStorage.getItem("ricordami");
            if (user && ricordami) {
                next({ path: `/${pageAfterLogin}` });
            } else {
                next();
            }
        },
    },
    {
        path: "",
        redirect: `/${pageAfterLogin}`,
    },
    {
        //Presenze
        path: "/presenze",
        component: () => import("@/views/Presenze.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Documenti
        path: "/documenti",
        name: "Documenti",
        component: () => import("@/views/Documenti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Richieste
        path: "/richieste",
        name: "Richieste",
        component: () => import("@/views/Richieste.vue"),
        meta: { requiresAuth: true },
    },
    {
        //BancaOre
        path: "/banca",
        name: "BancaOre",
        component: () => import("@/views/BancaOre.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Automezzi
        path: "/automezzi",
        name: "Automezzi",
        component: () => import("@/views/Automezzi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Nota Spese
        path: "/spese",
        name: "NotaSpese",
        component: () => import("@/views/NotaSpese.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Rimborsi km
        path: "/rimborsi",
        name: "Rimborsi",
        component: () => import("@/views/Rimborsi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Interventi
        path: "/interventi",
        name: "Interventi",
        component: () => import("@/views/Interventi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Tickets
        path: "/tickets",
        name: "Tickets",
        component: () => import("@/views/Tickets.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Timesheet
        path: "/timesheet",
        name: "Timesheet",
        component: () => import("@/views/Timesheet.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Clienti
        path: "/clienti",
        name: "Clienti",
        component: () => import("@/views/Clienti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Presenze
        path: "/rapportini",
        component: () => import("@/views/Rapportini.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Progetti
        path: "/progetti",
        name: "Progetti",
        component: () => import("@/views/Progetti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Leads
        path: "/leads",
        name: "Leads",
        component: () => import("@/views/Leads.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Sondaggi
        path: "/sondaggi",
        name: "Sondaggi",
        component: () => import("@/views/Sondaggi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Confindustria
        path: "/conf",
        name: "Confindustria",
        component: () => import("@/views/Confindustria.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Programma Lavoro
        path: "/programma",
        name: "ProgrammaLavoro",
        component: () => import("@/views/ProgrammaLavoro.vue"),
        //component: () => import("@/custom/ProgrammaLavoro.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Ordini
        path: "/ordini",
        name: "OrdiniInterni",
        component: () => import("@/views/OrdiniInterni.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Profilo
        path: "/profilo",
        name: "Profilo",
        component: () => import("@/views/Profilo.vue"),
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem("userInfo");

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (user == null) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
